export default [
  {
    key: 'idCard',
    label: 'field.idCard',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'image',
    label: 'field.image',
    thClass: 'text-left',
    tdClass: 'text-left',
    type: 'image',
  },
  {
    key: 'name',
    label: 'field.name',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'company',
    label: 'field.company',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'department',
    label: 'field.department',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'manager',
    label: 'field.supervisor',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'position',
    label: 'field.position',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'username',
    label: 'field.username',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'ipaddress',
    sortable: false,
    sortField: '',
    label: 'field.ipAddress',
  },
  {
    key: 'lastActivityAt',
    sortable: false,
    sortField: '',
    label: 'field.lastActiveDate',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    label: 'field.updateDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
